<!--店铺装修-->
<template>
  <div class="furnish">
    <components :is="componentsName" :app-info="appInfo" :editor-id="editorId" @change="changeCom" />
  </div>
</template>

<script>
  import furnishCard from './furnishComponents/furnishCard'
  import furnishDetail from './furnishComponents/furnishDetail'
  export default {
    name: 'furnish',
    components: { furnishCard, furnishDetail },
    data() {
      return {
        componentsName: 'furnishCard',
        editorId: '',
        appInfo: {}
      }
    },
    methods: {
      changeCom(info) {
        this.componentsName = info.name
        this.appInfo = info.appInfo
        this.editorId = info.editorId
      }
    }
  }
</script>

<style lang="scss" scoped>
  .furnish{
    width: 100%;
    height: 100%;
  }
</style>
